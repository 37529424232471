.modal p {
  margin: 10px;
}

/* @media only screen and (max-width: 321px) {
  .myModalClass {
    width: 80%;
  }
  .modal p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 360px) {
  .myModalClass {
    width: 85%;
  }
  .modal p {
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 411px) {
  .myModalClass {
    width: 90%;
  }
  .modal p {
    font-size: 0.9rem;
  }
}

@media (max-width: 280px) {
  .myModalClass {
    width: 95%;
  }
  .modal p {
    font-size: 0.76rem;
  }
}
@media (min-width: 280.02px) and (max-width: 311px) {
  .myModalClass {
    width: 75%;
  }
  .modal p {
    font-size: 0.78rem;
  }
}
@media (min-width: 311.02px) and (max-width: 320px) {
  .myModalClass {
    width: 80%;
  }
  .modal p {
    font-size: 0.79rem;
  }
}
@media (min-width: 320.02px) and (max-width: 375px) {
  .myModalClass {
    width: 85%;
  }
  .modal p {
    font-size: 0.85rem;
  }
}
@media (min-width: 375.02px) and (max-width: 414px) {
  .myModalClass {
    width: 85%;
  }
  .modal p {
    font-size: 0.9rem;
  }
}
@media (min-width: 414.02px) and (max-width: 540px) {
  .myModalClass {
    width: 90%;
  }
  .modal p {
    font-size: 0.95rem;
  }
}
.myModalClass {
  font-size: 100%;
} */

.color-blue {
  color: rgb(50, 141, 245);
}

.text-size-1-0 {
  font-size: 1rem;
}

.blue-button {
  color: rgb(50, 141, 245);
  border: 1px solid rgb(50, 141, 245);
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
/* input[type="file"] {
  display: none;
} */

input {
  padding: 4px;
  color: #3598db;
  border-radius: 8px;
  border-color: #3598db;
  width: 70%;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  /* border: 1px solid #ccc; */
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}
.register-here {
  color: rgb(50, 141, 245);
  text-align: center;
  cursor: pointer;
}
.image img {
  display: block;
  margin: 10px auto;
}

.image_dialog {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.image_dialog img {
  width: 90px;
  /* height: "auto"; */
  object-fit: "cover";
  max-height: 100px;
  /* height: 100px; */
  border-radius: 10px;
}

.group-card {
  box-shadow: 1px 0px 10px #888888;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
}
.forth,
.back {
  width: 40%;
  display: flex;
  align-items: center;
}
.back {
  justify-content: flex-end;
}
.group-card i {
  padding: 10px;
}
.group-card p {
  font-size: 1.1rem;
  font-weight: 500;
}
.walk {
  color: rgb(165, 221, 34);
}
.delete {
  color: red;
}
.edit {
  color: blue;
}
.attribute {
  color: rgb(50, 141, 245);
  font-weight: 500;
}
.value {
  color: black;
  font-weight: 500;
}
.places-functionality p {
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
}
.text-center {
  font-weight: 500;
}
.location {
  display: flex;
  justify-content: center;
}
.location div {
  display: flex;
  flex-direction: column;
}
.dialog-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(50, 141, 245);
}
.search {
  width: 100%;
  display: flex;
  align-items: center;
}
.search i {
  font-size: 1.7rem;
  margin-bottom: 20px;
  margin-left: 20px;
}
.color {
  color: blue;
}

.multiselect-item {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  cursor: pointer;
  /* box-shadow: 1px 0px 10px #888888; */
  /* margin: auto; */
  padding: 5px 10px;
  /* border-radius: 10px; */
  /* margin-bottom: 5px; */
}

.multiselect-list {
  padding: 5px;
  overflow-y: auto;
}

.form-items {
  padding: 10px;
  margin-bottom: 10px;
  overflow-y: auto;
}

.multiselect-item-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 1px 0px 10px #888888;
  margin: auto;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.list-item-card {
  align-items: center;
  width: 98%;
  box-shadow: 1px 0px 10px #888888;
  margin: auto;
  padding: 5px 5px;
  border-radius: 10px;
  margin-bottom: 8px;
  min-height: 30px;
}

/* img {
  max-width: 100%;
} */

.size-100 {
  width: 100px;
}

.round-corners {
  width: 90%;
  box-shadow: 1px 0px 10px #888888;
  margin: auto;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.items-spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items-left img span {
  float: left;
}

.items-right {
  float: right;
  margin-right: 20px;
}

.multiselect-item-title {
  display: flex;
  align-items: center;
}
.country i {
  color: pink;
  font-size: 1.7rem;
}
.country p {
  font-weight: 500px;
}
.sort {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.font-class {
  font-weight: 500;
  font-size: 1rem;
}
.flex-container-middle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.flex-container-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}
.flex-container-right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}
.flex-item-left {
  min-width: 20px;
  align-items: center;
  flex-grow: 1;
}
.flex-item-middle {
  align-items: center;
  flex-grow: 999;
}
.flex-item-right {
  align-items: center;
  flex-grow: 4;
}
.truncate {
  /* display: inline-block;
  width: 100px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: 500;
  font-size: 1rem;
  margin: 0px 5px;
  /* background: teal; */
}
.sort p {
  /* font-weight: bold; */
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 1rem;
}
.html-reader {
  /* margin-top: -90px;
  margin-left: -90px; */
  align-items: start;
  justify-content: flex-start;
  transform-origin: 0 0;
  transform: scale(0.75);
}
.html-reader-modal {
  /* margin: -20px; */
  /* width: 100%;
  height: 100%; */
  /* margin: 0px auto; */
  align-items: start;
  justify-content: flex-start;
}

.color-blue {
  color: "#3598DB";
}

.color-black {
  color: "black";
}

.menu-item {
  color: "#3598DB";
}

.html-resize-panel {
  transform-origin: 0 0;
  transform: scale(0.714);
}
.icon-with-text {
  text-align: center;
  /* font-size: 0.7rem; */
}
/* .small-text {
  font-size: 0.5rem;
} */
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  /* width: 100%; */
  font-size: 0.9rem;
}
.divider {
  line-height: 5%;
}
.tooltip {
  z-index: 100000000 !important;
  position: fixed;
}

@media (max-width: 280px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.41);
  }
}
@media (min-width: 280.02px) and (max-width: 320px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.48);
  }
}
@media (min-width: 320.02px) and (max-width: 375px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.52);
  }
}
@media (min-width: 375.02px) and (max-width: 414px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.62);
  }
}
@media (min-width: 414.02px) and (max-width: 540px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.7);
  }
}
@media (min-width: 540.02px) and (max-width: 640px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.41);
  }
}
@media (min-width: 640.02px) and (max-width: 768px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.625);
  }
}
@media (min-width: 768.02px) and (max-width: 981px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.5);
  }
}
@media (min-width: 981.02px) and (max-width: 1024px) {
  .html-resize-panel {
    transform-origin: 0 0;
    transform: scale(0.625);
  }
}
.center_vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 3px solid green;
}
