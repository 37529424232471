/* :root {
  --height: 40vh;
} */
.home {
  box-shadow: 1px 0px 10px #888888;
}

.home-panel {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 5px;
}

.home-resize-panel {
  /* display: inline-block; */
  /* font-size: 120%; */
  /* width: 140%;
  transform-origin: top left;
  transform: scale(0.714); */
  /* font-size: 12pt; */
}
/* .home-resize-panel image {
  max-width: 100%;
} */

/* .home-resize-panel * {
  font-size: 110%;
} */
/* .home-resize-panel a {
  font-size: 100%;
} */
/* .home-resize-panel p {
  font-size: 100%;
}

.home-resize-panel ol {
  font-size: 100%;
}

.home-resize-panel ul {
  font-size: 100%;
}

.home-resize-panel li {
  font-size: 130%;
}

.home-resize-panel div {
  font-size: 130%;
}

.home-resize-panel strong {
  font-size: 130%;
}

.home-resize-panel span {
  font-size: 130%;
} */

/* .scalefix {
  font-size: 130%;
} */

/* img {
  max-width: 100%;
} */

.loading {
  display: "flex";
  justify-content: "center";
  justify-items: "center";
}
/* 
@media (min-width: 280.02px) and (max-width: 320px) {
  .home-resize-panel {
    width: 200%;
    display: inline-block;
    transform-origin: top left;
    transform: scale(0.5);
    font-size: 170%;
  }
}
@media (min-width: 320.02px) and (max-width: 375px) {
  .home-resize-panel {
    width: 200%;
    transform-origin: 0 0;
    transform: scale(0.52);
    font-size: 170%;
  }
}

@media (min-width: 375.02px) and (max-width: 414px) {
  .home-resize-panel {
    width: 161%;
    transform-origin: top left;
    transform: scale(0.62);
  }
}
@media (min-width: 414.02px) and (max-width: 540px) {
  .home-resize-panel {
    width: 143%;
    transform-origin: top left;
    transform: scale(0.7);
  }
}
@media (min-width: 540.02px) and (max-width: 640px) {
  .home-resize-panel {
    width: 244%;
    transform-origin: top left;
    transform: scale(0.41);
  }
}
@media (min-width: 640.02px) and (max-width: 768px) {
  .home-resize-panel {
    width: 160%;
    transform-origin: top left;
    transform: scale(0.625);
  }
}
@media (min-width: 768.02px) and (max-width: 981px) {
  .home-resize-panel {
    width: 200%;
    transform-origin: top left;
    transform: scale(0.5);
  }
}
@media (min-width: 981.02px) and (max-width: 1024px) {
  .home-resize-panel {
    width: 160%;
    transform-origin: top left;
    transform: scale(0.625);
  }
} */
