.mobile-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  /* margin: 10px auto;
  padding: 0px;
  box-shadow: 1px 0px 10px #888888; */
  background-color: white;
  font-size: 100%;
}
.mobile-header div p {
  font-size: 0.8rem;
}
