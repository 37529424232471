:root {
  --schedwidth: 250px;
  --placeswidth: 350px;
  --mapheight: 2fr;
  --mapheightfixed: 500px;
  --schedule-menu: 70px;
  --scheduleheightfixed: 500px;
  --places-menu: 70px;
  --placesheightfixed: 500px;
  --homeheight: 3fr;
  --homeheightfixed: 500px;
  --sidemenupanel: 80px;
}
html,
body {
  font-family: sans-serif;
  margin: 0;
  --main-spacing: 15px 5px 10px 5px;
  --header-footer-spacing: 10px 5px;
}

#app-mobile {
  height: 100vh;

  /* grid container settings */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "header"
    "footer"
    "main";
}

#app-mobile-full-screen {
  height: 100vh;
  /* grid container settings */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main";
}

.app-mobile-header {
  grid-area: header;
  height: 65px;
  width: 100vw;
}

.app-mobile-footer {
  grid-area: footer;
  height: 45px;
  width: 100%;
}

.app-mobile-main {
  grid-area: main;
  /* padding: var(--main-spacing); */
  overflow: auto;
  height: 100vh;
  /* height: calc(100vh - (145px)); */
  width: 100vw;
}

.app-mobile-main-full-screen {
  grid-area: main;
  height: 100vh;
  width: 100vw;
}
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  display: flex;
}
.none {
  display: flex;
} */

/* html {
  height: 500px;
}

body {
  height: 500px;
} */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* .app-grid {
  width: 100vw;
  height: 100vh;
  margin: auto;
  display: grid;
  grid-template-columns: 80px 390px 48% 250px;
  grid-template-rows: 70px 56% 34%;
  grid-template-areas:
    "side-menu-panel places-menu-panel main-menu-panel schedule-menu-panel"
    "side-menu-panel places-panel home-panelx schedule-panelx"
    "side-menu-panel places-panel map-panelx schedule-panelx";
} */
.app-grid-map-fullscreen {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas: "map-panelx";
}
.app-grid-no-map {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70px 500px 500px 70px 500px 70px 500px;
  grid-template-areas:
    "main-menu-panel"
    "home-panelx"
    "places-menu-panel"
    "places-panel"
    "schedule-menu-panel"
    "schedule-panelx";
}
.app-grid-no-side {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70px var(--homeheightfixed) var(--mapheightfixed) var(--places-menu) var(--placesheightfixed) var(--schedule-menu) var(--scheduleheightfixed);
  grid-template-areas:
    "main-menu-panel"
    "home-panelx"
    "map-panelx"
    "places-menu-panel"
    "places-panel"
    "schedule-menu-panel"
    "schedule-panelx";
}

@media only screen and (min-width: 640px) {
  .app-grid-no-side {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: var(--placeswidth) 1fr;
    grid-template-rows: 70px var(--homeheightfixed) var(--mapheightfixed) var(--schedule-menu) var(--scheduleheightfixed);
    grid-template-areas:
      "places-menu-panel main-menu-panel"
      "places-panel home-panelx"
      "places-panel map-panelx"
      "places-panel schedule-menu-panel"
      "places-panel schedule-panelx";
  }
  .app-grid-no-map {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: var(--placeswidth) 1fr;
    grid-template-rows: 70px var(--homeheightfixed) var(--mapheightfixed) var(--schedule-menu) var(--scheduleheightfixed);
    grid-template-areas:
      "places-menu-panel main-menu-panel"
      "places-panel home-panelx"
      "places-panel schedule-menu-panel"
      "places-panel schedule-panelx";
  }
}

@media only screen and (min-width: 981px) {
  .app-grid-no-side {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: var(--sidemenupanel) var(--placeswidth) 1fr var(--schedwidth);
    grid-template-rows: 70px var(--homeheight) var(--mapheight);
    grid-template-areas:
      "side-menu-panel places-menu-panel main-menu-panel schedule-menu-panel"
      "side-menu-panel places-panel home-panelx schedule-panelx"
      "side-menu-panel places-panel map-panelx schedule-panelx";
  }
  .app-grid-no-map {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-template-rows: 70px 3fr 2fr;
    grid-template-areas:
      "places-menu-panel main-menu-panel schedule-menu-panel"
      "places-panel home-panelx schedule-panelx";
  }
}
.places-menu-panel {
  /* background-color: royalblue; */
  grid-area: places-menu-panel;
}
.main-menu-panel {
  /* background-color: red; */
  grid-area: main-menu-panel;
}
.schedule-menu-panel {
  /* background-color: blue; */
  grid-area: schedule-menu-panel;
}
.places-panel {
  /* background-color: green; */
  /* border: 1px solid #d3d3d3; */
  /* border-width: 5px;
  border-color: gray; */
  grid-area: places-panel;
}
.home-panelx {
  /* background-color: yellow; */
  border: 1px solid #d3d3d3;
  grid-area: home-panelx;
  overflow-y:auto;
  overflow-x: none;
  width: 100%;
}
.schedule-panelx {
  /* background-color: purple; */
  /* border: 1px solid #d3d3d3; */
  /* border-width: 5px;
  border-color: gray; */
  grid-area: schedule-panelx;
}
.map-panelx {
  /* background-color: plum; */
  /* border: 1px solid green; */
  grid-area: map-panelx;
}
.side-menu-panel {
  /* background-color: aqua; */
  grid-area: side-menu-panel;
}
/* .scroll-panel {
  background-color: gray;
  grid-area: scroll-panel;
  font-size: 1.7rem;
  display: flex;
} */
.scroll-icon {
  font-size: 1.7rem;
  margin-top: 100px;
  margin-left: 10px;
  color: blue;
}
.bottom-menu-panel {
  /* background-color: rgb(255, 240, 253); */
  grid-area: bottom-menu-panel;
  display: none;
}
.sticky-header {
  /* margin: auto; */
  position: fixed;
  top: 10;
  left: 10;
  /* margin-left: 100px; */
  /* width: 500px; */
  z-index: 99;
  background-color: white;
}
/* 
@media (max-width: 968px) {
  .app-grid {
    grid-template-columns: 100%;
    grid-template-rows: 80px 100% 80px;
    grid-template-areas:
      "main-menu-panel"
      "home-panelx"
      "bottom-menu-panel";
  }
  html {
    height: 100%;
  }

  body {
    height: 100%;
  }
  .bottom-menu-panel {
    display: auto;
  }
  .side-menu-panel {
    display: none;
  }
  .map-panelx {
    display: none;
  }
} */
