.schedule-header {
  background-color: rgb(233, 233, 233);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin-top: 2px;
  /* width: 90%;
  margin: auto; */
}
.schedule-header i {
  color: #3598db;
  font-size: 1.2rem;
  margin: 5px;
}
.schedule-header input {
  padding: 4px;
  color: #3598db;
  border-radius: 8px;
  border-color: #3598db;
  width: 70%;
  margin-right: 30px;
  height: 30px;
}
.schedule-header input:focus {
  border-color: rgb(19, 145, 218) !important;
  outline: blue;
}
.schedule-name {
  background-color: rgb(233, 233, 233);
}
.header-schedule {
  width: 15%;
}
.color-blue {
  color: #3598db;
}
.cursor-pointer {
  cursor: pointer;
}
