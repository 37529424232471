.places {
  width: 95%;
  background-color: white;
  box-shadow: 1px 0px 10px #888888;
  overflow-y: auto;
  padding-top: 15px;
  margin: 5px 5px;
  /* display: "flex";
  flex-direction: "column";
  height: "100%";
  overflow-y: "auto"; */
}

.alpha-order {
  margin: 0px 15px;
  font-size: 1rem;
  color: #3598db;
  margin-bottom: 0;
  font-weight: 500;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(155, 155, 155);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightblue;
}
.search {
  width: 85%;
  padding: 8px 10px;
  border-radius: 8px;
}
.places form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
i {
  cursor: pointer;
}
.places form i {
  margin-left: 10px;
}
.sticky-header {
  /* margin: auto; */
  position: fixed;
  top: 10;
  margin-left: 20px;
  width: 21%;
}
.background-color-yellow {
  background: "#fdcb6e";
}
