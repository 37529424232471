.home-header {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 600px;
  margin: 10px auto;
  padding: 0px;
  box-shadow: 1px 0px 10px #888888;
  background-color: white;
  font-size: 100%;
}
.home-header a p i {
  cursor: pointer;
}
.home-header div {
  margin: 3px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 100%;
}
.home-header p {
  margin-bottom: 0;
  font-size: 100%;
}
.home-footer {
  display: none;
  font-size: 100%;
}

.pointer {
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .side-none {
    display: none;
  }
}
@media screen and (max-width: 680px) {
  .none {
    display: none !important;
  }
}
@media screen and (min-width: 680px) {
  .mobile {
    display: none !important;
  }
}
@media screen and (max-width: 680px) {
  .mobile {
    display: none;
  }
}
@media screen and (max-width: 960px) {
  .tablet {
    display: none;
  }
}
