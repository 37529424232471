.schedule-card {
  display: flex;
  align-items: center;
  padding: 5px;
  box-shadow: 1px 0px 10px #888888;
  width: 95%;
  border-radius: 15px;
  cursor: pointer;
  margin: 5px auto;
  font-size: 0.9rem;
}
.calendar-icon {
  color: #3598db;
  margin: 5px 5px;
  font-size: 1rem;
}
.schedule p {
  margin: 0;
}
.schedule-time p {
  margin: 0;
  font-weight: 400;
}
.schedule-date {
  font-weight: 500;
  color: #3598db;
  font-size: 1rem;
  margin: 0px 15px;
}
.all-schedules {
  /* margin-top: 75px; */
  overflow-y: auto;
}
