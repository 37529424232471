.vertical-sidebar {
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: aliceblue;
  text-align: center;
  line-height: normal;
  font-size: 0.5rem;
}
.vertical-sidebar p {
  color: #3598db;
  font-size: 0.9rem;
  cursor: pointer;
  padding: 5px 3px;
}
.vertical-sidebar hr {
  width: 100%;
  color: #3598db;
  /* margin: auto; */
  margin-top: 0px; 
  margin-bottom: 0px;
  /* height: 1px; */
  border: 0;
}
.vertical-sidebar p {
  margin: 0;
  padding: 0.5rem; 
}
