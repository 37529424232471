.places-header {
  background-color: rgb(233, 233, 233);
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin-top: 2px;
}
.places-header input {
  padding: 4px;
  color: #3598db;
  border-radius: 8px;
  border-color: #3598db;
  width: 70%;
  height: 30px;
}
.places-header input:focus {
  border-color: rgb(19, 145, 218) !important;
  outline: blue;
}
.places-header i {
  display: block;
  /* color: #1495ec; */
  margin: 10px 20px;
  font-size: 1.2rem;
}

.color-blue {
  color: "#3598DB";
}
.color-yellow {
  color: "#fdcb6e";
}
