.place-card {
  display: flex;
  align-items: center;
  padding: 5px;
  box-shadow: 1px 0px 10px #888888;
  width: 97%;
  margin: 10px auto;
  border-radius: 15px;
  cursor: pointer;
  font-size: 0.9rem;
}
.place-card div {
  margin: 5px;
}
.place-card span {
  color: #3598db;
}
.color-blue {
  color: #3598db;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}
.place-card img {
  width: 90px;
  /* height: "auto"; */
  object-fit: "cover";
  max-height: 100px;
  /* height: 100px; */
  border-radius: 10px;
}
.place-card p {
  margin: 2px 4px;
  font-size: 0.8rem;
  line-height: 0.9rem;
}
